import React from "react";
import styled from "styled-components";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentCenter, NakedLink, Sidebar, SidebarContent, SmallIcon } from "./UI";
import AuthorAvatar from "./AuthorAvatar";
import { faCalendar, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { List, ListItemButton, ListItemText, Skeleton } from "@mui/material";

const AuthorName = styled.h2`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
`;

const AuthorUsername = styled.h4`
  margin-top: 3px;
  margin-bottom: 0;
  color: rgb(83, 100, 113);
  font-size: 14px;
  font-weight: 400;
`;

const AuthorTitle = styled.h5`
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Joined = styled.h6`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  color: #444;
`;

const AvatarLink = styled(NakedLink)`
  &:hover {
    text-decoration: none;
  }
`;

const SidebarListItemButton = styled(ListItemButton)`
  font-weight: bold;
`;

export default function AuthorSidebar(props) {
  return (
    <Sidebar>
      <SidebarContent>
        <div>
          <ContentCenter>
            {!props.user && <Skeleton variant="circular" width={90} height={90} />}
            {props.user && (
              <AvatarLink to={`/${props.user.username}`}>
                <AuthorAvatar large>{props.user.avatar}</AuthorAvatar>
              </AvatarLink>
            )}
          </ContentCenter>
          <AuthorName>
            {!props.user && <Skeleton variant="rectangular" width="100%" height={24} />}
            {props.user && <NakedLink to={`/${props.user.username}`}>{props.user.name}</NakedLink>}
          </AuthorName>
          <AuthorUsername>
            {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
            {props.user && <NakedLink to={`/${props.user.username}`}>@{props.user.username}</NakedLink>}
          </AuthorUsername>
          <AuthorTitle>
            {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
            {props.user && props.user.title}
          </AuthorTitle>
          <Joined>
            {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
            {props.user && (
              <>
                <SmallIcon icon={faCalendar} /> Joined {moment.unix(props.user.createdAt).format("MMMM YYYY")}
              </>
            )}
          </Joined>
        </div>
      </SidebarContent>
      <List>
        {!props.user && (
          <>
            <SidebarListItemButton>
              <ListItemText>
                <Skeleton variant="rectangular" width="100%" height={24} />
              </ListItemText>
            </SidebarListItemButton>
          </>
        )}
        {props.user && (
          <SidebarListItemButton component={Link} to={`/${props.user.username}/mentions`}>
            <ListItemText
              primary={
                <span>
                  <FontAwesomeIcon flip={"horizontal"} icon={faCommentDots} /> Mentions
                </span>
              }
            />
          </SidebarListItemButton>
        )}
      </List>
    </Sidebar>
  );
}
