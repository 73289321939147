import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import EditPost from "./EditPost";
import Post from "./Post";
import { Lane } from "../UI";
import { useSession } from "../../util/Hooks";
import { lazy } from "../../util/Utils";
import { useHandler } from "@aux4/use-handler";
import { CANCEL_EDIT_POST, EDIT_POST, NEW_POST_CREATED, POST_REMOVED, RELOAD_POSTS } from "../../event/PostEvents";
import PostApi from "../../api/PostApi";

const NoPostHeading = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;

  & p {
    font-size: 16px;
    font-weight: normal;
  }

  & span {
    display: block;
    margin-bottom: 5px;
    font-size: 60px;
  }
`;

export default function PostLane(props) {
  const session = useSession();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(
    lazy(async () => {
      setLoading(true);
      await reloadPosts();
      setLoading(false);
    }),
    []
  );

  useHandler(
    lazy(async () => {
      setLoading(true);
      await reloadPosts();
      setLoading(false);
    }),
    [NEW_POST_CREATED, POST_REMOVED, RELOAD_POSTS]
  );

  useHandler(
    (eventType, postId) => {
      const existingPosts = [...posts];
      const post = existingPosts.find(post => post.id === postId);
      if (post) {
        PostApi.findById(post.id).then(fullPost => {
          const index = existingPosts.indexOf(post);
          existingPosts[index] = fullPost;
          fullPost.edit = true;
          setPosts(existingPosts);
        });
      }
    },
    [EDIT_POST]
  );

  useHandler(
    (eventType, postId) => {
      const existingPosts = [...posts];
      const post = existingPosts.find(post => post.id === postId);
      post.edit = false;
      setPosts(existingPosts);
    },
    [CANCEL_EDIT_POST]
  );

  async function reloadPosts() {
    const posts = await props.loadPosts();
    setPosts(posts);
  }

  return (
    <Lane>
      {session && <EditPost />}
      {!loading && posts.length === 0 && (
        <NoPostHeading>
          <span>🤔</span> No posts yet
          <p>You can be the first one to post here!</p>
        </NoPostHeading>
      )}
      {!loading &&
        posts.map(post => (
          <Fragment key={post.id}>
            {post.edit && <EditPost content={post} />}
            {!post.edit && <Post content={post} />}
          </Fragment>
        ))}
      {loading &&
        [{}, {}, {}].map((post, index) => (
          <Fragment key={`empty-${index}`}>
            <Post />
          </Fragment>
        ))}
    </Lane>
  );
}
