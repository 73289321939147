import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../util/Utils";
import PostApi from "../api/PostApi";
import UserApi from "../api/UserApi";
import { Grid } from "@mui/material";
import AuthorSidebar from "../component/AuthorSidebar";
import DefaultPage from "../component/DefaultPage";
import PostLane from "../component/post/PostLane";
import { reloadPosts } from "../event/PostActions";
import { Metadata } from "../component/Meta";

export default function UserPage() {
  const navigate = useNavigate();
  const { username } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    setTitle(username);
    reloadPosts();
  }, [username]);

  async function loadPosts() {
    const user = await UserApi.findByUsername(username);
    if (!user) {
      navigate("/");
      return;
    }

    setUser(user);

    setTitle(user.name);

    return await PostApi.postsByUser(user.id);
  }

  return (
    <DefaultPage>
      {user && (
        <Metadata
          title={`${user.name} · Jesus with me`}
          description={`${user.name} is sharing the experience of living with Jesus. Check out ${user.name} stories and also share your experiences about your journey with Jesus.`}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={3} md={2}>
          <AuthorSidebar user={user} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <PostLane loadPosts={loadPosts} />
        </Grid>
      </Grid>
    </DefaultPage>
  );
}
