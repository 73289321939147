import React, { useEffect, useState } from "react";
import { Form } from "../UI";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { validateEmail } from "../../validation/Validation";
import moment from "moment";
import { normalizeUsername } from "../../text/Normalizer";
import UserApi from "../../api/UserApi";
import { Link } from "react-router-dom";

const Birthday = styled.h4`
  margin: 0 0 5px;
`;

export default function SignUpForm(props) {
  const [ignoreFields] = useState(props.ignoreFields || []);
  const [form, setForm] = useState(
    props.value || {
      name: "",
      title: "Member",
      username: "",
      email: "",
      month: "",
      day: "",
      year: ""
    }
  );
  const [emailValid, setEmailValid] = useState(true);
  const [birthdayValid, setBirthdayValid] = useState(true);
  const [usernameAlreadyInUse, setUsernameAlreadyInUse] = useState(false);
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);

  useEffect(() => {
    if (props.value) {
      const form = props.value;
      setForm(form);

      const birthday = form.birthday ? moment(form.birthday, "YYYY-MM-DD") : undefined;
      if (form.birthday !== undefined && birthday.isValid()) {
        form.month = birthday.format("MM");
        form.day = birthday.format("DD");
        form.year = birthday.format("YYYY");
      }
    }
  }, [props.value]);

  const handleChange = event => {
    const newForm = { ...form };
    newForm[event.target.name] = event.target.value;

    const birthday = `${newForm.year}-${newForm.month}-${newForm.day}`;
    if (newForm.year !== "" && newForm.month !== "" && newForm.day !== "" && moment(birthday, "YYYY-MM-DD").isValid()) {
      newForm.birthday = birthday;
    } else {
      newForm.birthday = undefined;
    }

    setForm(newForm);

    props.onChange(newForm);
    props.onValidate(isValid(newForm, usernameAlreadyInUse, emailAlreadyInUse));
  };

  const handleInputEmail = event => {
    event.target.value = event.target.value.toLowerCase();
  };

  const handleEmailBlur = async () => {
    const validEmail = validateEmail(form.email);
    setEmailValid(validEmail);

    let emailInUse = emailAlreadyInUse;

    if (validEmail) {
      emailInUse = await UserApi.isEmailInUse(form.email);
      setEmailAlreadyInUse(emailInUse);
    }

    props.onValidate(isValid(form, usernameAlreadyInUse, emailInUse));
  };

  const handleBirthdayBlur = () => {
    const invalidBirthday =
      form.day !== undefined &&
      form.day !== "" &&
      form.month !== undefined &&
      form.month !== "" &&
      form.year !== undefined &&
      form.year !== "" &&
      form.birthday === undefined;
    setBirthdayValid(!invalidBirthday);

    if (!invalidBirthday) {
      const birthday = form.birthday ? moment(form.birthday, "YYYY-MM-DD") : undefined;
      const newForm = { ...form };
      newForm.month = birthday.format("MM");
      newForm.day = birthday.format("DD");
      newForm.year = birthday.format("YYYY");
      setForm(newForm);
    }
  };

  const isValid = (form, usernameAlreadyInUse, emailAlreadyInUse) => {
    return (
      (ignoreFields.includes("name") || form.name !== "") &&
      (ignoreFields.includes("email") || validateEmail(form.email)) &&
      (ignoreFields.includes("username") || (form.username !== undefined && form.username !== "")) &&
      (ignoreFields.includes("birthday") || (form.birthday !== undefined && form.birthday !== "")) &&
      !usernameAlreadyInUse &&
      !emailAlreadyInUse
    );
  };

  const maxLength = e => {
    e.target.value = e.target.value.substring(0, e.target.maxLength);
  };

  const handleUsernameInput = e => {
    e.target.value = normalizeUsername(e.target.value);
  };

  const handleUsernameBlur = async () => {
    if (form.username === undefined || form.username === "") {
      return;
    }

    const usernameInUse = await UserApi.isUsernameInUse(form.username);
    setUsernameAlreadyInUse(usernameInUse);

    props.onValidate(isValid(form, usernameInUse, emailAlreadyInUse));
  };

  return (
    <Form>
      <Grid container spacing={2}>
        {!ignoreFields.includes("name") && (
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              value={form.name}
              fullWidth
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        {!ignoreFields.includes("title") && (
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={form.title}
              fullWidth
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        {!ignoreFields.includes("username") && (
          <Grid item xs={12}>
            <TextField
              label="Username"
              name="username"
              value={form.username}
              error={usernameAlreadyInUse}
              helperText={usernameAlreadyInUse ? "This username is already in use" : undefined}
              fullWidth
              InputProps={{ readOnly: props.readOnly, startAdornment: <FontAwesomeIcon icon={faAt} /> }}
              InputLabelProps={{ shrink: true }}
              onInput={handleUsernameInput}
              onChange={handleChange}
              onBlur={handleUsernameBlur}
            />
          </Grid>
        )}
        {!ignoreFields.includes("email") && (
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={form.email}
              error={!emailValid || emailAlreadyInUse}
              helperText={
                !emailValid ? "Invalid email" : emailAlreadyInUse ? "This email is already in use" : undefined
              }
              fullWidth
              onBlur={handleEmailBlur}
              onInput={handleInputEmail}
              onChange={handleChange}
              InputProps={{
                readOnly: props.readOnly,
                endAdornment: emailAlreadyInUse ? (
                  <InputAdornment>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/signin"
                      state={{ email: form.email }}>
                      Log In
                    </Button>
                  </InputAdornment>
                ) : undefined
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        {!ignoreFields.includes("birthday") && (
          <>
            <Grid item xs={12}>
              <Birthday>Date of birth</Birthday>
              <span>
                This information will not be shared. Please confirm your age (format: <strong>MM/DD/YYYY</strong>).
              </span>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  label="Month"
                  name="month"
                  placeholder="MM"
                  value={form.month}
                  error={!birthdayValid}
                  helperText={birthdayValid ? undefined : "Invalid date"}
                  fullWidth
                  inputProps={{ inputMode: "numeric", maxLength: 2 }}
                  InputLabelProps={{ shrink: true }}
                  onInput={maxLength}
                  onChange={handleChange}
                  onBlur={handleBirthdayBlur}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  label="Day"
                  name="day"
                  placeholder="DD"
                  value={form.day}
                  error={!birthdayValid}
                  fullWidth
                  inputProps={{ inputMode: "numeric", maxLength: 2 }}
                  InputLabelProps={{ shrink: true }}
                  onInput={maxLength}
                  onChange={handleChange}
                  onBlur={handleBirthdayBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  label="Year"
                  name="year"
                  placeholder="YYYY"
                  value={form.year}
                  error={!birthdayValid}
                  fullWidth
                  inputProps={{ inputMode: "numeric", maxLength: 4 }}
                  InputLabelProps={{ shrink: true }}
                  onInput={maxLength}
                  onChange={handleChange}
                  onBlur={handleBirthdayBlur}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );
}
