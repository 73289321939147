import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { List, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import TopicApi from "../api/TopicApi";
import { lazy } from "../util/Utils";

const SidebarListItemButton = styled(ListItemButton)`
  font-weight: bold;
`;

const SidebarItemText = styled(ListItemText)`
  && span {
    font-weight: ${props => (props.selected ? "bold" : "normal")} !important;
  }
`;

export default function HashTagList(props) {
  const { hashtag } = useParams();
  const [loading, setLoading] = useState(false);
  const [hashtags, setHashtags] = useState([]);

  useEffect(
    lazy(async () => {
      setLoading(true);
      const response = await TopicApi.list();
      setHashtags(response.topics);
      setLoading(false);
    }),
    []
  );

  useEffect(() => {
    if (hashtag && !hashtags.map(item => item.name).includes(hashtag)) {
      setHashtags([{ name: hashtag }].concat(hashtags));
    }
    if (props.onHashtag) {
      props.onHashtag(hashtags.find(item => item.name === hashtag));
    }
  }, [hashtag, hashtags]);

  return (
    <List>
      {!loading &&
        hashtags.map(itemHashtag => (
          <SidebarListItemButton key={itemHashtag.id} component={Link} to={`/hashtag/${itemHashtag.name}`}>
            <SidebarItemText primary={`#${itemHashtag.name}`} selected={itemHashtag.name === hashtag} />
          </SidebarListItemButton>
        ))}
      {loading && (
        <>
          <SidebarListItemButton>
            <Skeleton variant="rectangular" width="100%" height={24} />
          </SidebarListItemButton>
          <SidebarListItemButton>
            <Skeleton variant="rectangular" width="100%" height={24} />
          </SidebarListItemButton>
          <SidebarListItemButton>
            <Skeleton variant="rectangular" width="100%" height={24} />
          </SidebarListItemButton>
        </>
      )}
    </List>
  );
}
