import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Skeleton } from "@mui/material";
import PostCard from "./PostCard";
import PostMenu from "./PostMenu";
import { useSession } from "../../util/Hooks";
import ExternalLinkPostReference from "./reference/ExternalLinkPostReference";
import PostApi from "../../api/PostApi";
import { Markdown } from "../Markdown";
import YouTubePostReference from "./reference/YouTubePostReference";
import { openPostDialog } from "../../event/PostActions";
import PostActions from "./PostActions";
import ReadTime from "./ReadTime";
import ReferenceCarousel from "./reference/ReferenceCarousel";
import PostLinkReference from "./reference/PostLinkReference";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDove } from "@fortawesome/free-solid-svg-icons";

const TestimonyButton = styled(Button)`
  && {
    margin-bottom: 10px;
  }
`;

export default function Post(props) {
  const session = useSession();
  const [content, setContent] = useState(props.content);
  const [expanded, setExpanded] = useState(props.expanded);

  useEffect(() => {
    const postContent = props.content;
    setContent(postContent);
  }, [props.content]);

  const handleExpand = async expanded => {
    if (expanded) {
      const post = await PostApi.findById(props.content.id);
      setContent(post);
    }
    setExpanded(expanded);
  };

  return (
    <PostCard
      expanded={props.expanded}
      content={props.content}
      headerAction={session && content && session.user.id === content.user.id && <PostMenu postId={content.id} />}
      actions={<PostActions content={content} onExpand={handleExpand} expanded={expanded} />}>
      {!content && <Skeleton variant="rectangular" width="100%" height={80} />}
      {content && content.id && (
        <>
          <Markdown text={expanded ? content.text : content.short || content.text} post={content} />
          {session &&
            session.user.id === content.user.id &&
            content.references.filter(reference => reference.type === "HASHTAG" && reference.reference === "#pray")
              .length > 0 && (
              <TestimonyButton
                variant="contained"
                color="success"
                size="small"
                startIcon={<FontAwesomeIcon icon={faDove} />}
                onClick={() =>
                  openPostDialog({
                    text: `Your story starts here...\n\n#testimony`,
                    references: [
                      {
                        type: "LINK",
                        reference: "pray",
                        id: content.id
                      }
                    ]
                  })
                }>
                Write your Testimony
              </TestimonyButton>
            )}
          {content.references && content.references.filter(reference => reference.type === "URL").length > 0 && (
            <ReferenceCarousel>
              {content.references &&
                content.references
                  .filter(reference => reference.type === "URL")
                  .map((reference, index) => {
                    if (
                      reference.url.toLowerCase().indexOf("youtube") > -1 ||
                      reference.url.toLowerCase().indexOf("youtu.be") > -1
                    ) {
                      return <YouTubePostReference key={index} {...reference} />;
                    }
                    return <ExternalLinkPostReference key={index} {...reference} />;
                  })}
            </ReferenceCarousel>
          )}
          {content.short !== content.text && !expanded && <ReadTime content={content} onExpand={handleExpand} />}
          {content.references
            .filter(reference => reference.type === "LINK")
            .map(reference => (
              <PostLinkReference postId={reference.id} linkType={reference.reference} />
            ))}
        </>
      )}
    </PostCard>
  );
}
