import { textTransform } from "../text/TextTransformer";
import React, { useEffect, useState } from "react";
import { lazy } from "../util/Utils";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

export function MarkdownLink({ href, ...otherProps }) {
  if (href.indexOf(process.env.REACT_APP_URL) === -1) {
    return <a href={href} target="_blank" {...otherProps} />;
  }
  return <a href={href} {...otherProps} />;
}

export function MarkdownCode(props) {
  return <code className={props.className}>{props.children}</code>;
}

export function Markdown(props) {
  const [text, setText] = useState("");

  useEffect(
    lazy(async () => {
      const content = await textTransform(props.text, props.post);
      setText(content);
    }),
    [props.text, props.post]
  );

  return (
    <ReactMarkdown
      components={{ a: MarkdownLink, code: MarkdownCode, pre: props1 => <div>{props1.children}</div> }}
      remarkPlugins={[remarkGfm]}
      children={text}
    />
  );
}
