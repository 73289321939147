import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import PostApi from "../../../api/PostApi";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import { faCaretDown, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthorAvatar from "../../AuthorAvatar";
import { Link } from "react-router-dom";
import { Markdown } from "../../Markdown";
import { AuthorName, AuthorUsername, MarkdownContent, PostTime, SmallIcon } from "../../UI";
import moment from "moment";
import { useSession } from "../../../util/Hooks";
import { forceReloadComments } from "../../../event/PostActions";
import { useHandler } from "@aux4/use-handler";
import { FORCE_RELOAD_COMMENTS } from "../../../event/PostEvents";

const Comment = styled.div`
  position: relative;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.03);
`;

const CommentField = styled.div`
  padding: 0 10px 10px 10px;

  & textarea {
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
  }
`;

const CommentAvatar = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;

  && a {
    text-decoration: none;
    color: inherit;
  }
`;

const CommentContent = styled.div`
  padding: 0 10px 0 72px;
`;

const CommentHeader = styled.div`
  && a {
    text-decoration: none;
    color: inherit;
  }
`;

const CommentText = styled.div`
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 18px;
`;

const CommentMarkdownContent = styled(MarkdownContent)`
  && {
    padding: 0 !important;
    font-size: 18px;
  }

  && p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  && p:not(:first-child) {
    margin-block-start: 5px;
  }

  && p:not(:last-child) {
    margin-block-end: 5px;
  }
`;

const CommentAction = styled(IconButton)`
  && {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export default function PostComments(props) {
  const session = useSession();
  const [text, setText] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    (async () => {
      await reloadComments();
    })();
  }, [props.postId]);

  useHandler(
    (eventType, postId) => {
      if (postId === props.postId) {
        reloadComments().then(() => {});
      }
    },
    [FORCE_RELOAD_COMMENTS]
  );

  const handleChange = e => {
    setText(e.target.value);
  };

  const createComment = async () => {
    await PostApi.comment(props.postId, text);
    setText("");
    await reloadComments();
  };

  const reloadComments = async () => {
    const comments = await PostApi.comments(props.postId);
    setComments(comments);
  };

  return (
    <div>
      {session && (
        <CommentField>
          <TextField
            placeholder="Share your love about that"
            multiline
            fullWidth
            value={text}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton color="primary" sx={{ flexGrow: 1 }} disabled={text === ""} onClick={createComment}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </IconButton>
              )
            }}
          />
        </CommentField>
      )}
      {comments.map(comment => (
        <Comment key={`comment-${comment.id}`}>
          {session && session.user.id === comment.user.id && (
            <PostCommentMenu postId={props.postId} commentId={comment.id} />
          )}
          <CommentAvatar>
            <Link to={`/${comment.user.username}`}>
              <AuthorAvatar>{comment.user.avatar}</AuthorAvatar>
            </Link>
          </CommentAvatar>
          <CommentContent>
            <CommentHeader>
              <AuthorName>
                <Link to={`/${comment.user.username}`}>{comment.user.name}</Link>
              </AuthorName>
              <AuthorUsername>
                <Link to={`/${comment.user.username}`}>@{comment.user.username}</Link>
              </AuthorUsername>
              {comment.createdAt && (
                <>
                  {" · "}
                  <Tooltip title={moment.unix(comment.createdAt).format("hh:mm A · MMM DD, YYYY")}>
                    <PostTime>{moment.unix(comment.createdAt).fromNow()}</PostTime>
                  </Tooltip>
                </>
              )}
            </CommentHeader>
            <CommentText>
              <CommentMarkdownContent>
                <Markdown text={comment.text} post={comment} />
              </CommentMarkdownContent>
            </CommentText>
          </CommentContent>
        </Comment>
      ))}
    </div>
  );
}

function PostCommentMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteComment = async () => {
    await PostApi.deleteComment(props.postId, props.commentId);
    handleClose();
    forceReloadComments(props.postId);
  };

  return (
    <Fragment>
      <CommentAction onClick={handleClick}>
        <SmallIcon icon={faCaretDown} />
      </CommentAction>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={deleteComment}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTrash} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
