import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSession } from "../../util/Hooks";

const SuggestionItem = styled.a`
  color: #33b5f0;
  cursor: pointer;
`;

export default function SuggestionBox(props) {
  const session = useSession();
  const { hashtag, username } = useParams();
  const [hasUsername, setHasUsername] = useState(false);
  const [hasHashtag, setHasHashtag] = useState(false);

  useEffect(() => {
    const text = props.text || "";

    const lowercaseText = text.toLowerCase();
    if (username) {
      if (session && session.user.username === username) {
        setHasUsername(true);
      } else {
        setHasUsername(lowercaseText.includes(`@${username.toLowerCase()}`));
      }
    }
    if (hashtag) {
      setHasHashtag(lowercaseText.includes(`#${hashtag.toLowerCase()}`));
    }
  }, [props.text, username, session, hashtag]);

  const handleSuggestion = function (text) {
    if (props.onSuggest) {
      let appendedText;
      if (props.text === "") {
        appendedText = `${text} `;
      } else {
        appendedText = ` ${text}`;
      }
      props.onSuggest(appendedText);
    }
  };

  return (
    <div>
      {username && !hasUsername && (
        <SuggestionItem onClick={() => handleSuggestion(`@${username.toLowerCase()}`)}>
          tag @{username.toLowerCase()}
        </SuggestionItem>
      )}
      {hashtag && !hasHashtag && (
        <SuggestionItem onClick={() => handleSuggestion(`#${hashtag.toLowerCase()}`)}>
          add #{hashtag.toLowerCase()}
        </SuggestionItem>
      )}
    </div>
  );
}
