import { bibleRule, hashTagRule, urlRule, usernameRule } from "./TagRules";

export async function textTransform(text, post) {
  if (!text) {
    return text;
  }

  const rules = [usernameRule(), hashTagRule(), urlRule(), bibleRule(post)];

  const references = {};

  await Promise.all(rules.map(rule => rule.transform(text, references)));

  let finalText = text;
  Object.keys(references).forEach(ref => (finalText = finalText.replaceAll(ref, references[ref])));

  return finalText;
}

export function rule(regex, transformer) {
  return {
    transform: async (text, references) => {
      const matches = text.match(regex);
      if (matches) {
        for (let match of matches) {
          references[match] = await transformer(match);
        }
      }
    }
  };
}
