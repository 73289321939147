import React, { useState } from "react";
import { Avatar } from "@mui/material";

export default function AuthorAvatar(props) {
  const [large] = useState(props.large);
  return (
    <>
      {large && (
        <Avatar sx={{ backgroundColor: "#00A3ED", width: 90, height: 90, fontSize: 40 }}>{props.children}</Avatar>
      )}
      {!large && <Avatar sx={{ backgroundColor: "#00A3ED" }}>{props.children}</Avatar>}
    </>
  );
}
