import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import PostCard from "./PostCard";
import { useSession } from "../../util/Hooks";
import PostApi from "../../api/PostApi";
import { cancelEditPost, newPostCreated } from "../../event/PostActions";
import SuggestionBox from "./SuggestionBox";
import { ButtonBar } from "../UI";
import PostLinkReference from "./reference/PostLinkReference";

const TextArea = styled(TextField)`
  & input {
    outline: none;
    border: none;
  }
`;

const PostButton = styled(Button)`
  && {
    margin: 0 0 0 auto;
    flex: 0 0 auto;
  }
`;

const PostTextArea = styled(TextArea)`
  & textarea {
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
  }
`;

const SELECTED_TEXT = "Your story starts here...";

export default function EditPost(props) {
  const session = useSession();
  const [content, setContent] = useState(props.content || { text: "" });
  const [block, setBlock] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setContent(props.content || { text: "" });
  }, [props.content]);

  useEffect(() => {
    setTimeout(() => {
      textAreaRef.current.selectionStart = textAreaRef.current.value.indexOf(SELECTED_TEXT);
      textAreaRef.current.selectionEnd = textAreaRef.current.selectionStart + SELECTED_TEXT.length;
      textAreaRef.current.focus();
    }, 0);
  }, [props.content]);

  const handleChange = event => {
    const updatedContent = { ...content };
    updatedContent.text = event.target.value;
    setContent(updatedContent);
  };

  const cancelEdit = () => {
    cancelEditPost(content.id);
  };

  const createPost = async () => {
    setBlock(true);

    if (content.id) {
      await PostApi.update({
        id: content.id,
        text: content.text,
        references: content.references
      });
      newPostCreated(content);
    } else {
      const createdPost = await PostApi.create({
        text: content.text,
        references: content.references
      });
      setContent({ text: "" });
      newPostCreated(createdPost);
    }

    setBlock(false);
  };

  const handleSuggestion = text => {
    const newContent = { ...content };
    newContent.text += text;
    setContent(newContent);
  };

  return (
    <>
      {session && (
        <PostCard
          expanded={props.expanded}
          content={{ user: session.user }}
          actions={
            <>
              {/*<label htmlFor="icon-button-file">
            <Input accept="image/*" id="icon-button-file" type="file" />
            <IconButton color="primary" aria-label="upload picture" component="span">
              <FontAwesomeIcon icon={faCamera} />
            </IconButton>
          </label>*/}
              <SuggestionBox text={content.text} onSuggest={handleSuggestion} />
              <ButtonBar>
                {content.id && (
                  <Button color="error" onClick={cancelEdit}>
                    Cancel
                  </Button>
                )}
                <PostButton
                  variant="contained"
                  color="primary"
                  disabled={content.text === "" || block}
                  onClick={createPost}>
                  Post
                </PostButton>
              </ButtonBar>
            </>
          }>
          <PostTextArea
            variant="standard"
            placeholder="Your story starts here..."
            multiline
            fullWidth
            value={content.text}
            onChange={handleChange}
            inputRef={textAreaRef}
            InputProps={{
              disableUnderline: true
            }}
          />
          {(content.references || [])
            .filter(reference => reference.type === "LINK")
            .map(reference => (
              <PostLinkReference postId={reference.id} linkType={reference.reference} />
            ))}
        </PostCard>
      )}
    </>
  );
}
