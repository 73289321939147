import React, { useState } from "react";
import { ContentCenter, Sidebar, SidebarContent } from "./UI";
import AuthorAvatar from "./AuthorAvatar";
import styled from "styled-components";
import HashTagList from "./HahsTagList";

const Hashtag = styled.h1`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
`;

const HashtagDescription = styled.div`
  margin-top: 8px;
  text-align: center;
  color: #666;
`;

export default function HashtagSidebar(props) {
  const [hashtag, setHashtag] = useState({ name: props.hashtag });

  const handleHashtag = selectedHashtag => {
    if (selectedHashtag) {
      setHashtag(selectedHashtag);
    }
  };

  return (
    <Sidebar>
      <SidebarContent>
        <div>
          <ContentCenter>
            <AuthorAvatar large>#</AuthorAvatar>
          </ContentCenter>
          <ContentCenter>
            <Hashtag>#{hashtag.name}</Hashtag>
          </ContentCenter>
          <ContentCenter>
            <HashtagDescription>{hashtag.description}</HashtagDescription>
          </ContentCenter>
        </div>
      </SidebarContent>
      <HashTagList onHashtag={handleHashtag} />
    </Sidebar>
  );
}
