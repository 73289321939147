export function setTitle(title) {
  if (!title || title === "") {
    document.title = "Jesus with me";
    return;
  }
  document.title = `${title} · Jesus with me`;
}

export function lazy(asyncCallback) {
  return () => {
    asyncCallback().catch((err) => console.error(err));
  };
}

export function objectToQueryParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
}
