import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, CardActions, CardHeader, CardMedia, Skeleton, Tooltip } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import AuthorAvatar from "../AuthorAvatar";
import PostComments from "./comment/PostComments";
import { AuthorName, AuthorUsername, MarkdownContent, PostTime } from "../UI";

const PostHeader = styled(CardHeader)`
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const Article = styled.article`
  margin: 0 auto;
  max-width: ${props => (props.expanded ? "700px" : "600px")};
  width: 100%;
`;

const PostCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`;

const PostInternalCard = styled(Card)`
  && {
    border-radius: 0;
    box-shadow: none;
  }
`;

export default function PostCard(props) {
  const [content, setContent] = useState(props.content);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  return (
    <Article expanded={props.expanded}>
      <PostInternalCard>
        <PostHeader
          avatar={
            <>
              {!content && <Skeleton variant="circular" width={40} height={40} />}
              {content && (
                <Link to={`/${content.user.username}`}>
                  <AuthorAvatar>{content.user.avatar}</AuthorAvatar>
                </Link>
              )}
            </>
          }
          action={props.headerAction}
          title={
            <div>
              <AuthorName>
                {!content && <Skeleton variant="rectangular" width="100%" height={16} />}
                {content && <Link to={`/${content.user.username}`}>{content.user.name}</Link>}
              </AuthorName>
              <AuthorUsername>
                {!content && <Skeleton variant="rectangular" width="100%" height={16} />}
                {content && <Link to={`/${content.user.username}`}>@{content.user.username}</Link>}
              </AuthorUsername>
              {content && content.createdAt && (
                <>
                  {" · "}
                  <Tooltip title={moment.unix(content.createdAt).format("hh:mm A · MMM DD, YYYY")}>
                    <PostTime>
                      {!content && <Skeleton variant="rectangular" width="100%" height={16} />}
                      {content && <Link to={`/posts/${content.id}`}>{moment.unix(content.createdAt).fromNow()}</Link>}
                    </PostTime>
                  </Tooltip>
                </>
              )}
              {content && content.updatedAt && content.createdAt !== content.updatedAt && (
                <>
                  {" · "}
                  <Tooltip title={moment.unix(content.updatedAt).format("hh:mm A · MMM DD, YYYY")}>
                    <PostTime>edited</PostTime>
                  </Tooltip>
                </>
              )}
            </div>
          }
          subheader={content && content.user.title}
        />
        {content && content.image && (
          <Link to={`/${content.user.username}/${content.id}`}>
            <CardMedia component="img" image={content.image} />
          </Link>
        )}
        <MarkdownContent>{props.children}</MarkdownContent>
        <PostCardActions>{props.actions}</PostCardActions>
        {content && content.id && !props.disableComments && <PostComments postId={content.id} />}
      </PostInternalCard>
    </Article>
  );
}
