import Api from "./Api";

const TopicApi = {
  list: async function () {
    const response = await Api.get("/topics");
    return response.data;
  }
};

export default TopicApi;
