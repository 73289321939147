import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Lane = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 600px;
  max-width: calc(100% - 2px);
  border-left: solid 1px #eee;
  border-right: solid 1px #eee;

  & > article:not(:last-child) {
    border-bottom: solid 1px #e6e6e6;
  }
`;

export const ActionButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
`;

export const NakedLink = styled(Link)`
  text-decoration: none;
  color: #33b5f0;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #0072a5;
  }
`;

export const SmallIcon = styled(FontAwesomeIcon)`
  font-size: 12px !important;
`;

export const Form = styled.div`
  margin: 30px 0 0 0;

  & p {
    line-height: 1.4rem;
  }

  & > div {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h2`
  margin-top: 0;
  font-size: 20px;
`;

export const Page = styled.div`
  & p {
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
  }
`;

export const PageTitle = styled.h1`
  font-weight: 300;
  font-size: 40px;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  & button,
  & a {
    margin-left: 10px !important;
  }
`;

export const PostReference = styled(Card)`
  margin-bottom: 10px;
`;

export const AuthorName = styled.span`
  font-weight: bold;
`;

export const AuthorUsername = styled.span`
  margin-left: 5px;
  font-size: 0.7rem;
  color: rgb(83, 100, 113);
`;

export const PostTime = styled.span`
  font-size: 0.7rem;
  color: rgb(83, 100, 113);
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Sidebar = styled.div`
  border-left: solid 1px #e6e6e6;
  padding: 20px 0;
`;

export const SidebarContent = styled(ContentCenter)`
  padding: 20px;
`;

export const MarkdownContent = styled(CardContent)`
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 20px;
  line-height: 32px;
  word-break: break-word;
  font-weight: 400;

  && {
    padding: 0 16px;
  }

  & p:not(:first-child) {
    margin-top: 2em;
  }

  & blockquote {
    padding-left: 30px;
    font-weight: 300;
    line-height: 40px;
    color: rgba(117, 117, 117, 1);
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 28px;
  }

  & h1 {
    margin-top: 0.6em;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 40px;
    font-size: 32px;
    font-weight: 700;
  }

  & h2 {
    margin-top: 3.14em;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 2px;
    font-size: 22px;
    font-weight: 600;
  }

  & ol,
  & ul {
    margin-left: 0;
    padding-left: 0;
  }

  & li {
    margin-top: 1.14em;
    margin-left: 30px;
    line-height: 28px;
  }

  & a {
    text-decoration: none;
    color: #33b5f0;
  }

  & a:hover {
    text-decoration: underline;
  }

  & a:visited {
    text-decoration: none;
    color: #0072a5;
  }
`;
