import React from "react";
import styled from "styled-components";

const ReadTimeBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: rgba(117, 117, 117, 1);

  & span,
  & a {
    cursor: pointer;
  }
`;

export default function ReadTime(props) {
  return (
    <ReadTimeBox>
      <a onClick={props.onExpand}>read full post</a>
      <span onClick={props.onExpand}>{props.content.readingTime} min read</span>
    </ReadTimeBox>
  );
}
