import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PostApi from "../../../api/PostApi";
import { Skeleton } from "@mui/lab";
import PostCard from "../PostCard";
import { Markdown } from "../../Markdown";
import { Link } from "react-router-dom";

const PostReferenceLink = styled(Link)`
  margin-top: 10px;
  &:hover {
    text-decoration: none !important;
    background-color: #fafafa !important;
  }
`;

const PostReferenceBox = styled.div`
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 10px;
`;

export default function PostLinkReference(props) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    PostApi.findById(props.postId).then(post => setPost(post));
  }, [props.postId]);

  if (!post) {
    return <Skeleton width="100%" height={40} />;
  }

  return (
    <PostReferenceLink to={`/posts/${post.id}`} target="_blank">
      <PostReferenceBox>
        <PostCard expanded={false} content={post} disableComments={true}>
          <Markdown text={post.short || post.text} post={post} />
        </PostCard>
      </PostReferenceBox>
    </PostReferenceLink>
  );
}
