import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../util/Utils";
import PostApi from "../api/PostApi";
import DefaultPage from "../component/DefaultPage";
import { Grid } from "@mui/material";
import PostLane from "../component/post/PostLane";
import { reloadPosts } from "../event/PostActions";
import AuthorSidebar from "../component/AuthorSidebar";
import UserApi from "../api/UserApi";
import { Metadata } from "../component/Meta";

export default function UserMentionsPage() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  async function loadPosts() {
    return await PostApi.postsByReference("username", username.toLowerCase());
  }

  useEffect(() => {
    UserApi.findByUsername(username).then(user => {
      if (!user) {
        navigate("/");
        return;
      }

      setUser(user);
      setTitle(`${user.name} · Mentions`);
      reloadPosts();
    });
  }, [username]);

  return (
    <DefaultPage>
      {user && (
        <Metadata
          title={`${user.name} · Jesus with me`}
          description={`${user.name} is sharing the experience of living with Jesus. Check out ${user.name} stories and also share your experiences about your journey with Jesus.`}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={3} md={2}>
          <AuthorSidebar user={user} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <PostLane loadPosts={loadPosts} />
        </Grid>
      </Grid>
    </DefaultPage>
  );
}
