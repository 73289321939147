import { rule } from "./TextTransformer";
import { HASHTAG_REGEX, URL_REGEX, USERNAME_REGEX } from "./Regex";
import { normalizeUsername } from "./Normalizer";
import UserApi from "../api/UserApi";

const URL = process.env.REACT_APP_URL;

export function hashTagRule() {
  return rule(HASHTAG_REGEX, async text => {
    return `[${text.substring(0)}](${URL}/hashtag/${text.substring(1)})`;
  });
}

export function usernameRule() {
  return rule(USERNAME_REGEX, async text => {
    const user = await UserApi.findByUsername(text.substring(1));
    return `[${user.name}](${URL}/${normalizeUsername(user.username)})`;
  });
}

export function urlRule() {
  return rule(URL_REGEX, async text => {
    const url = text.startsWith("http") ? text : `http://${text}`;
    return `[${text}](${url})`;
  });
}

export function bibleRule(post) {
  return {
    transform: async function (text, references) {
      if (post.references && post.references.length > 0) {
        const bibleReferences = post.references.filter(reference => reference.type === "BIBLE");
        bibleReferences.forEach(reference => {
          const bookReference = `${reference.chapter}${reference.verse ? `.${reference.verse}` : ""}${
            reference.verseEnd ? `-${reference.verseEnd}` : ""
          }`;

          const link = `https://www.bible.com/bible/111/${reference.book}.${bookReference}.NIV`;
          references[reference.reference] = `[${reference.reference}](${link})`;
        });
      }
    }
  };
}
